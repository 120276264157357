@import 'styles/sass/variables';
@import 'styles/sass/mixins/rem';

.before_the_image {
  direction: rtl;
}

.image {
  border-radius: rem(16);

  @media (max-width: $breakpoints-md) {
    &_no_border {
      border-radius: 0;
    }
  }
}

//button
.paragraphButton {
  width: 100%;

  button {
    min-width: rem(230);
    margin: auto;
    width: rem(230);
  }
}

.single_paragraph {
  &_title {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      font-size: rem(26);
      line-height: rem(31);
      font-weight: bold;
    }
  }
  &_content {
    ul {
      padding-left: 0;
    }
  }
}
